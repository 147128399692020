.notification {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: -300px;
  width: 500px;
  padding: 18px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #ca60d6;
  font-size: 18px;
  text-align: center;
  z-index: 999;
  animation: slideIn 0.5s forwards, slideOut 0.6s forwards 3s;
}

.success {
  background-color: #fff;
  border-bottom: 6px solid #4CAF50;
}

.error {
  background-color: #fff;
  border-bottom: 6px solid #f44336;
}

.warning {
  background-color: #fff;
  border-bottom: 6px solid #ff9800;
}


@keyframes slideIn {
  from {
    top: -300px;
  }
  to {
    top: 20px;
  }
}

@keyframes slideOut {
  from {
    top: 20px;
  }
  to {
    top: -300px;
  }
}

@media screen and (max-width: 500px) {
  .notification {
    width: 95%;
  }
}