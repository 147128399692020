.albumH4 {
  color: #874491;
  margin-bottom: 20px;
  font-family: 'NexaBold';
}

.contentVideo>div {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.contentVideo video {
  width: 320px;
  height: 568, 89px;
  background-size: cover;
}

.contentVideo h3 {
  color: #CA60D6;
  font-size: 22px;
  margin-bottom: 10px;
}

.contentVideo p {
  font-size: 18px;
  color: #464646;
  width: 90%;
}

/* Estilo para album de fotos */
.albums {
  display: flex;
  flex-wrap: wrap;
}

.album {
  width: calc(33.33% - 20px);
  /* 3 álbuns por linha */
  margin: 10px;
  cursor: pointer;
}

.album img {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  transition: transform 0.2s ease-in;
}

.album img:hover {
  transform: scale(1.03);
}

.album h3 {
  margin-top: 10px;
  color: #CA60D6;
}

/* Estilos para o modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.modal-content {
  padding: 20px;
  position: relative;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
}

.modal .btn-fechar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 24px;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #000000;
}

.album-photos {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.album-photos img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Estilos para o lightbox */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.lightbox-content img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  max-height: 80vh;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
}
.prev-button,
.next-button {
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 28px;
  color: #CA60D6;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
  opacity: 0.9;
}

.prev-button {
  left: 150px;
}

.next-button {
  right: 150px;
}

@media screen and (max-width: 1024px) {
  .album {
    width: calc(50% - 20px);
  }
  .prev-button {
    left: 5px;
  }
  
  .next-button {
    right: 5px;
  }
}

@media screen and (max-width: 768px) {
  .album {
    width: calc(100% - 20px);
  }

  .modal-content {
    width: 95%;
  }

  .lightbox-content {
    max-width: 95%;
  }

  .prev-button {
    left: 5px;
  }
  
  .next-button {
    right: 5px;
  }

  .titulos-home {
    margin-top: 20px;
  }

  .albumH4 {
    margin-top: 20px;
  }

  .contentVideo>div {
    padding: 10px;
    gap: 20px;
    flex-direction: column;
  }

  .contentVideo video {
    width: 100%;
  }


  .contentVideo h3 {
    font-size: 18px;
  }

  .contentVideo p {
    font-size: 14px;
    width: 100%;
  }
}