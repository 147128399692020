 /* @keyframes jump {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  
  40% {
    transform: translateY(-10px);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-text {
  font-size: 2em;
  font-weight: bold;
}

.loading-text span {
  color: #CA60D6;
}

.loading-text span {
  display: inline-block;
  animation: jump 1.5s infinite;
}

.loading-text span:nth-child(1) {
  animation-delay: 0.1s;
}

.loading-text span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-text span:nth-child(3) {
  animation-delay: 0.3s;
}

.loading-text span:nth-child(4) {
  animation-delay: 0.4s;
}

.loading-text span:nth-child(5) {
  animation-delay: 0.5s;
}

.loading-text span:nth-child(6) {
  animation-delay: 0.6s;
}

.loading-text span:nth-child(7) {
  animation-delay: 0.7s;
}

.loading-text span:nth-child(8) {
  animation-delay: 0.8s;
}

.loading-text span:nth-child(9) {
  animation-delay: 0.9s;
}

.loading-text span:nth-child(10) {
  animation-delay: 1.0s;
}

.loading-text span:nth-child(11) {
  animation-delay: 1.2s;
}
.loading-text span:nth-child(12) {
  animation-delay: 1.3s;
}
.loading-text span:nth-child(13) {
  animation-delay: 1.4s;
}
.loading-text span:nth-child(14) {
  animation-delay: 1.5s;
}
 */


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #CA60D6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
