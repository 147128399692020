/* Fontes */
@font-face {
  font-family: 'Nikoovers';
  src: url('./fonts/Nikoovers-GORam.ttf') format('truetype');
}

@font-face {
  font-family: 'NexaRegular';
  src: url('./fonts/NexaRegular.otf') format('opentype');
}

@font-face {
  font-family: 'NexaBold';
  src: url('./fonts/Nexa\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'NexaLight';
  src: url('./fonts/Nexa\ Light.otf') format('opentype');
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  margin: 0 auto;
  font-family: sans-serif;
  overflow-x: hidden;
  font-family: 'NexaRegular';
}

.padding {
  height: 60px;
}

.container {
  background-color: #fff;
  position: relative;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  min-height: 100%;
  transition: all 0.5s ease;
}

.sidebar.open~.container {
  left: 250px;
  width: calc(100% - 250px);
}

.content {
  padding: 20px;
  height: auto;
  width: 100%;
}

.btn-outros {
  color: #ca60d6;
  border: 1px solid #ca60d6;
}

button {
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  border: 2px solid #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s;
}

button:hover {
  scale: 1.03;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.toast-css {
  background-color: #fcebfe;
  color: #ca60d6;
}

@media (max-width: 700px){
  .container {
    width: 100%;
    left: 0;
  }

  .content {
    padding: 10px;
  }
}