.register-box,
.usuarios-table {
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.register-box h2 {
  font-family: 'NexaBold';
  color: #874491;
}


.usuarios-lista {
  margin-top: 30px;
}

.usuarios-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.usuarios-table th,
.usuarios-table td {
  border-bottom: 1px solid #f5f5f5;
  padding: 20px;
  text-align: left;
}

.usuarios-table th {
  background-color: #CA60D6;
  color: #fff;
  font-family: 'NexaBold';
  text-transform: uppercase;
  padding: 15px;
}

.usuarios-table tr:hover {
  background-color: #f5f5f5;
}

.perfil-basic {
  color: #c19595;
  font-family: 'NexaBold';
}

.perfil-premium {
  color: #888888;
  font-family: 'NexaBold';
}

.btn-cadastrar {
  border-color: #CA60D6;
  color: #CA60D6;
}

.perfil-master {
  color: #874491;
  font-family: 'NexaBold';
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 15px;
  color: #CA60D6;
}

.pagination button {
  color: #CA60D6;
  padding: 4px;
  border: 1px solid #CA60D6;
}

.modal-actions {
  width: 100% !important;

  .btn-edit {
    background-color: green;
    border-radius: 16px;
  }

  .btn-close {
    background-color: red;
    border-radius: 16px;

  }
}

.status-ativo {
  padding: 2px 4px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgreen;
  color: green;
}

.status-inativo {
  padding: 2px 4px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightcoral;
  color: rgb(238, 224, 224);

}

@media (max-width: 600px) {
  .usuarios-table {
    overflow: scroll;
  }
}