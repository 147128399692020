/* Login.css */

.login-container {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/bannerLogin.svg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-box {
  background-color: #87449190;
  backdrop-filter: blur(5px);
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 450px;
  max-width: 95%;
  height: auto;
}

.login-box h2 {
  margin-top: 0;
}

.input-group {
  margin: 20px 0;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus {
  border: 2px solid #ca60d6;
  outline: none;
}

.login-box p {
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: .8px;
}

.login-box p a {
  color: #fff;
  text-decoration: underline;
}

.password-input {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #ca60d6;
  opacity: .7;
  transform: translateY(-50%);
  cursor: pointer;
}



@media (max-width: 768px){
  .login-container {
    background-position: 5% -20px;
  }
}