/* Ícone flutuante com nome */
.chat-floating-icon-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  z-index: 1000;
}

.chat-floating-label {
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 8px;
  border-radius: 10px;
  color: #ca60d6;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s;
}

.chat-floating-icon-wrapper:hover .chat-floating-label {
  color: #ca60d6;
  /* Altera a cor ao passar o mouse */
}

/* Ícone flutuante */
.chat-floating-icon {
  width: 50px;
  height: 50px;
  background-color: #ca60d6;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.chat-floating-icon:hover {
  background-color: #b04cb8;
}

/* Modal do chat */
.chat-modal {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 400px;
  max-height: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 500px) {
    width: 90%;
  }
}

.chat-header {
  background-color: #f1ddf3;
  color: #b04cb8;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h3 {
  margin: 0;
  font-size: 18px;
}

.chat-close-btn {
  background: none;
  border: none;
  color: #b04cb8;
  font-size: 18px;
  cursor: pointer;
}

/* Corpo do chat */
.chat-body {
  padding: 10px;
  flex: 1;
  background-color: #f9f9f9;
  overflow-y: auto;
}

.chat-messages {
  flex: 1;
}

.chat-message {
  margin: 10px 0;

  >strong {
    letter-spacing: .8px;
    color: #555;
    font-family: 'NexaBold';
  }
}

.chat-message.user {
  text-align: right;
}

.chat-message.assistant {
  text-align: left;
}

.message-text.user {
  color: #999;
  /* Azul para a usuária */
}

.message-text.assistant {
  color: #999;
  /* Rosa para a resposta da IA */
}

.user-name {
  font-weight: bold;
  color: #555;
  /* Azul para o nome "Você" */
}

/* Rodapé do chat */
.chat-footer {
  padding: 10px;
  display: flex;
  gap: 10px;
  border-top: 1px solid #ddd;
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-footer button {
  padding: 10px 20px;
  background-color: #f1ddf3;
  color: #b04cb8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-footer button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Nome PositiIA */
.highlight-ia {
  color: #ca60d6;
  /* Rosa */
  font-family: 'NexaBold';
}
.highlight-ia2 {
  color: #b04cb8;
  font-family: 'NexaBold';
  margin-left: 2px;
}

.typing-indicator {
  color: #999;
  font-style: italic;
}