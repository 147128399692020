.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #F5F5F5;
  padding: 6px 14px;
  z-index: 2;
  transition: all 0.5s ease;
  color: #464646;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.logo-details img {
  width: 100%;
  margin-right: 6px;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #464646;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 30%;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
  right: 0;
}

.sidebar .nav-list {
  margin-top: 60px;
  height: 100%;
}

.sidebar.open .nav-list {
  margin-top: 10px;
}

.sidebar li {
  position: relative;
  list-style: none;
  color: #464646;
}

.sidebar.open .nav-list .tooltip {
  display: none;
}

.nav-list .tooltip {
  position: absolute;
  background-color: #CA60D6;
  color: #fff;
  padding: 6px 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px 0px;
  top: 0;
  left: 60px;
  display: none;
  z-index: 2;
  transition: opacity .5s ease;
}

.nav-list li:hover .tooltip {
  display: block;
}

.sidebar li div .i:last-child {
  margin-left: auto;
}

.sidebar li a,
.sidebar li div {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #F5F5F5;
  color: #464646 ;
  padding: 10px;
  margin-bottom: 10px;
  gap: 20px;
}


.sidebar li a:hover,
.sidebar li div:hover {
  background: #CA60D6;
  color: #F5F5F5;
}

#div-map {
  background-color: #CA60D6;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 100px;
}

#a-map {
  padding: 5px;
  background: transparent;
  opacity: .7;
  width: 100%;
  color: #fff;
}

#a-map:hover {
  color: #fff;
  opacity: 1;
}

.sidebar.open #div-map {
  width: auto;
}

.sidebar li a .links_name,
.sidebar li div span {
  color: #464646;;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name,
.sidebar.open li div span {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover .i,
.sidebar li div:hover span,
.sidebar li div:hover .i {
  transition: all 0.5s ease;
  color: #F5F5F5;
}

.sidebar .profile {
  position: fixed;
  height: 80px;
  width: 78px;
  left: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  border-top: 2px solid #eee;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar .profile #log_out svg {
  background: transparent;
  color: #464646;
}

.sidebar #log_out {
  border: none;
  font-size: 20px;
  transition: all .3s ease-in-out;
  cursor: pointer;
  margin-left: -50%;
}

.sidebar.open .profile #log_out {
  margin: 0;
}

.sidebar .profile #log_out:hover svg{
  scale: 1.1;
  color: #464646;
}

.sidebar .profile span {
  color: #464646;
  font-size: 20px;
  display: none;
}

.sidebar.open .profile span {
  display: block;
}

.sidebar.open #hide-icon {
  display: block;
}

.sidebar #hide-icon {
  display: none;
}

@media (max-width: 700px) {
  .sidebar {
    margin-left: -250px;
  }
  .sidebar.open {
    margin-left: 0;
  }
  .sidebar .profile {
    display: none;
  }

  .sidebar.open .profile {
    display: flex;
  }
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}