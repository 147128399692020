.upload-form {
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-form h2 {
  color: #874491;
  font-family: 'NexaBold';
  margin-bottom: 8px;
}

.info {
  margin-bottom: 40px;
}

/* Estilo para os campos de entrada de texto */
.upload-form input[type="file"],
.upload-form input[type="text"],
.upload-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.upload-form input:focus,
.upload-form textarea:focus {
  border: 2px solid #ca60d6;
  outline: none;
}

.upload-form textarea {
  height: 100px;
}