.roda {
  width: auto;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
}

.roda>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.rodaH2 {
  padding: 20px;
  color: #874491;
  font-family: 'NexaBold';
  display: flex;
  justify-content: space-between;
}

.roda label {
  font-size: 12px;
}

.roda input {
  margin-top: 8px;
  padding: 1px 3px;
  border-radius: 4px;
  border-color: #CA60D6;
}

.roda-container {
  width: 60%;
  height: auto;
  margin: 0 auto;
}

.roda-container canvas {
  width: 100%;
}


.saveRoda {
  border-color: #CA60D6;
  color: #CA60D6;
  float: right;
}

/* Adicione esses estilos ao seu arquivo CSS */
.rodas-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  width: 100%;
  color: #CA60D6;
}

.rodasH2 {
  color: #874491;
  margin-left: 10px;
  font-family: 'NexaBold';
}

.rodas-container p {
  color: #CA60D6;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: .5px;

}

.rodas-container>div {
  width: calc(50% - 10px);
  min-width: 270px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}




/* CSS modal instruções */
.instrucoes-button {
  background-color: #ca60d6;
  color: white;
  font-size: 16px;
}

.instrucoes-button:hover {
  background-color: #a94bad;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  max-width: 900px;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.modal-content h2 {
  margin: 20px 0;
  color: #ca60d6;
}

.modal-content h3 {
  margin: 20px 0;
  color: #ca60d6;
}

.modal-content p {
  margin-bottom: 10px;
}

.modal-content ul {
  padding-left: 20px;
}

.modal-content li {
  margin-bottom: 10px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #999;
}

.modal-close:hover {
  color: #ca60d6;
}





@media (max-width: 768px) {
  .roda-container {
    width: 100%;
  }

  .rodas-container>div {
    width: calc(50% - 10px);
    min-width: 100%;
  }
  .rodaH2 {
    font-size: 20px;
  }
  
}