.cursos,
.ebooks,
.comentarios {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Estilos básicos do Swiper */
.swiper-container {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Estilo para imagens grandes */
.large-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

/* Estilo para imagens pequenas */
.small-image {
  width: 250px;
  height: 350px;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.cursos {
  padding: 20px 0;
}

.swiper img:hover,
.ebooks .ebook img:hover {
  scale: 1.02;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #8a2be2 !important;
}

.link-bloked {
  position: relative;
}

.overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  padding: 3px;
  color: #fff;
  opacity: 0.7;
}

.link-bloked .i-bloked {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  font-size: 22px;
  z-index: 1;
}

.ebooks .ebook {
  position: relative;
}

.ebook:hover .overlayEbook {
  display: flex;
  align-items: center;
  justify-content: center;

}

.overlayEbook {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  padding: 3px;
  color: #fff;
  opacity: 0.7;
  display: none;
  font-size: 20px;
}

.ebooks .ebook img {
  width: 200px;
  height: 300px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  object-fit: cover;
  transition: all .3s ease-in-out;
}

/* Curso */
.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #464646;
}

.video-container h2 {
  color: #874491;
  font-family: 'NexaBold';
  margin-bottom: 10px;
}

.video-description {
  width: 100%;
  margin-bottom: 80px;
}

.div-video {
  width: calc(33.33% - 10px);
  margin-bottom: 40px;
}

.ferramenta {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.ferramenta a {
  text-decoration: underline;
}

.div-video video {
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.div-video h2 {
  font-size: 15px;
  color: #874491;
  font-family: 'NexaBold';
}

.div-video button {
  color: #464646;
  padding: 2px 0;
  border-bottom: 1px solid #888;
}

.comentarios {
  flex-direction: column;
}

.comentarios form label {
  color: #874491;
  font-family: 'NexaBold';
  font-size: 26px;
}

.comentarios form img {
  box-shadow: 2px 2px 5px #888888;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 5px;
}

.comentarios form {
  display: flex;
  flex-direction: column;
}

.comentarios textarea {
  resize: vertical;
  height: 100px;
  margin-bottom: 10px;
  padding: 5px;
  border-color: #888888;
}

.comentarios form button {
  background-color: #874491;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border: none;
  width: 100px;
  margin-left: auto;
}

.comments-section {
  margin-top: 20px;
  border-left: 2px solid #ccc;
  padding-left: 20px;
}

.comment {
  display: flex;
  margin-bottom: 20px;
}

.comment-details {
  flex-grow: 1;
  padding: 0 10px;
}

.comment-name {
  font-family: 'NexaBold';
  color: #874491;
  font-size: 18px;
}

.comment-date {
  color: #888;
  font-size: 0.8em;
  margin-left: 10px;
}

.comment-text {
  margin-top: 5px;
  color: #464646;
}

.comment img {
  box-shadow: 2px 2px 5px #888888;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}

/* positiveclass videos */
.video-class-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #464646;
}

.video-class-container h2 {
  color: #874491;
  font-family: 'NexaBold';
  margin-bottom: 10px;
}

.video-class-description {
  width: 100%;
  margin-bottom: 10px;
}

.div-class-video {
  width: 100%;
  margin-bottom: 40px;
}

.div-class-video video {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.div-class-video h2 {
  font-size: 15px;
  color: #874491;
  font-family: 'NexaBold';
}

.div-class-video button {
  color: #464646;
  padding: 2px 0;
  border-bottom: 1px solid #888;
}

.video-boasvindas {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 40px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 40px;
}

.video-boasvindas video {
  width: 500px;
  height: 250px;
}

.video-boasvindas>div {
  width: 50%;
}

.video-boasvindas>div h2 {
  color: #874491;
  font-family: 'NexaBold';
  margin-bottom: 20px;
}

.video-boasvindas>div p {
  font-size: 18px;
  color: #464646;
}

.boas-vindas-container .lista-itens {
  padding: 40px 0px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.lista-itens ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 40vh;
}

.lista-itens ul li a {
  font-size: 18px;
  color: #464646;
}

.lista-itens ul li {
  border-bottom: 1px solid #cccccc6c;
  padding: 8px;
}

.lista-itens ul li a .i {
  margin-right: 10px;
  font-size: 20px;
  color: #874491;
}

.lista-itens ul:last-child {
  justify-content: end;
}

.lista-itens ul li a img {
  width: 435px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all .3s ease-in-out;
}

.lista-itens ul li a img:hover {
  scale: 1.02;
}

@media screen and (max-width: 768px) {
  .video-container {
    flex-direction: column;
    height: auto;
  }

  .div-video {
    width: 100%;
  }

  .video-boasvindas {
    flex-direction: column;
  }

  .video-boasvindas video {
    width: 100%;
    height: 250px;
  }

  .video-boasvindas>div {
    width: 100%;
  }

  .boas-vindas-container .lista-itens {
    flex-direction: column;
  }

  .lista-itens ul {
    height: 400px;
    gap: 20px;
  }

  .lista-itens ul li a {
    font-size: 16px;
  }

  .lista-itens ul li a img {
    width: 100%;
  }
}