.cardBoasVindas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
}

.cardBoasVindas a img {
  width: 100%;
  /* max-width: 600px; */
  height: auto;
  /* margin-top: 40px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  transition: all .3s ease-in-out;
}

/* .cardBoasVindas a img:hover,
.novidades a img:hover {
  scale: 1.02;
} */

.titulos-home {
  color: #874491;
  margin-bottom: 20px;
  font-family: 'NexaBold';
}

.proximo-econtro,
.cardBoasVindas {
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 40px;
}

.encontro {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.encontro>div {
  padding: 0 60px 0 40px;
}

.encontro img {
  width: 280px;
  height: 350px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.encontro h5 {
  color: #CA60D6;
  margin-bottom: 6px;
  font-size: 12px;
  letter-spacing: .5px;
}

.encontro h2 {
  font-size: 22px;
  color: #CA60D6;
}

.encontro p {
  font-size: 16px;
  letter-spacing: .5px;
  color: #464646;
  margin: 5% auto;
}


.novidades {
  position: relative;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 40px;
}

.novidades a{
  color: #464646;
}

.novidades .swiper-container {
  width: 100%;
  height: auto;
}

.novidades .swiper-wrapper {
  display: flex;
}

.novidades .swiper-slide {

  color: #464646;
  margin: 0 auto;
}

.novidades .swiper-slide img {
  /* width: 100%;
  height: auto; */
  width: 380px; /* Preserve the max width */
  height: 300px; /* Preserve the max height */
  object-fit: cover;
  border-radius: 8px;
  margin-top: 20px;
  transition: all .3s ease-in-out;
}

.novidades .swiper-slide h3 {
  color: #CA60D6;
  margin-bottom: 5px;
}

.novidades .swiper-slide div {
  margin-top: 10px;
  width: 100%;
}

.content-youtube{
  display: flex;
}

.youtube-chanel {
  width: 50%;
  min-height: 400px;
  padding: 10px 10px 40px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.youtube-chanel iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Media queries para responsividade */
@media (max-width: 480px) {
  .swiper-slide h3 {
    font-size: 1em;
  }

  .swiper-slide p {
    font-size: 0.9em;
  }

  .novidades .swiper-slide img {
    width: 340px;
    height: 280px;
  }

  .novidades .swiper-slide div {
    width: 320px;
  }

  .content-youtube{
    flex-direction: column;
  }
  
  .youtube-chanel {
    width: 100%;
    
  }
}

@media (max-width: 768px) {
  .swiper-slide h3 {
    font-size: 1.1em;
  }

  .swiper-slide p {
    font-size: 0.95em;
  }
}

@media (max-width: 999px) {
  .novidades a>div {
    margin-top: 10px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .encontro>div {
    padding: 0 0px 0 20px;
  }

  .encontro h5 {
    font-size: 12px;
  }

  .encontro h2 {
    font-size: 22px;
  }

  .encontro p {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .proximo-econtro {
    padding: 20px 10px;
  }

  .encontro {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }

  .encontro>div {
    padding: 0;
  }

  .encontro img {
    width: 100%;
    height: auto;
  }

  .youtube-chanel iframe {
    height: 200px;
  }
}