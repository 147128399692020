.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 78px);
  height: 70px;
  padding: 10px;
  background-color: #F5F5F5;
  transition: all 0.5s ease;
  z-index: 2;
}

.usuario {
  position: fixed;
  right: 10px;
  top: 20px;
  border: 1px solid #464646;
  padding: 8px;
  border-radius: 40px;
  z-index: 2;
}

.usuario .link1 {
  font-size: 14px;
  color: #464646;
}
.usuario .link2 {
  font-size: 12px;
  color: #464646;
  display: none;
}

.header .logo-details #btn {
  position: absolute;
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  color: #464646;
}

@media (max-width: 700px) {
  .header{
    width: 100%;
  }

}

@media (max-width: 600px) {
  .usuario .link1 {
    display: none;
  }
  .usuario .link2 {
    display: block;
  }

}